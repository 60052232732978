import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-reservecourt',
  templateUrl: './reservecourt.component.html',
  styleUrls: ['./reservecourt.component.css']
})
export class ReservecourtComponent implements OnInit {

  member_id:any;
  data:any;
  email:any;
  servicedata:any;
  first_name:any;
  last_name:any;
  mobile:any;
  m_id:any;
  profileimage:any = 'assets/default-profile.png';
  status:any;
  startTime:any;

  active:boolean = true;
  myid:any;
  timeid:any;
  reserve:any = "";
  partners:any = '';
  reserveselection:boolean = true;
  partnerselection:boolean = false;
  slotselection:boolean = false;
  courtselection:boolean = false;
  mygroups:any = [];
  timeslot:any = [];

  fromtime:any = "";
  fbcourts:any = [];

  myfbcourtid:any = "";
  groupmembers:any = [];
  players:any = 2;

  successMessage:any;
  errorMessage:any;

  constructor(private common:CommonService, private router:Router,private spinnerService: Ng4LoadingSpinnerService) {
    this.member_id = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    if(!this.member_id) this.router.navigateByUrl('/login');
   }

  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile(){
    this.spinnerService.show();

    this.servicedata = {
      member_id:this.member_id,
      email:this.email,
    }
  
  this.common.serverdatapost('getMemberProfile?member_number='+this.member_id,this.servicedata).subscribe(
    res => {
          this.data = res;
          console.log(this.data.response);
          let userProfile = this.data.response;
          this.first_name = userProfile.first_name;
          this.last_name = userProfile.last_name;
          this.email = userProfile.email;
          this.mobile = userProfile.mobile;
          this.m_id = userProfile.id;
          this.profileimage = userProfile.profile;
          this.getBookingStatus();
          this.spinnerService.hide();
    },
    errors => {
      console.log(errors);
      this.spinnerService.hide();
    }
  )
}
 
    getBookingStatus(){

        this.common.serverdata('getBookingTimeStatus','').subscribe(
          res => {
                this.data = res;
                console.log(this.data.response.status);
                this.status = this.data.response.status;
                this.startTime = this.data.response.start;

                if(this.status == true){
                  this.active = false;
                }
                if(this.status == false){
                  alert('Reservation will start at '+this.startTime);
                }
          },
          errors => {
            console.log(errors);
          }
        )
    }

  setId(id, type, m1, m2, m3, m4){
    this.myid = id;
    console.log(this.myid);
    if(type == '1') this.groupmembers = [m1, m2];
    if(type == '2') this.groupmembers = [m1, m2, m3, m4];
    console.log(this.groupmembers);
    this.active = false;
  }

  setTime(id,from){
    // this.timeid = id;
    this.fromtime = from;
    // console.log(this.timeid);
    this.active = false;
  }

  reserveAs(reserve)
  {
    console.log(reserve);
    this.reserve = reserve;
    console.log(this.member_id);
    if(reserve == 'single') this.groupmembers = [this.member_id];
    else this.groupmembers = [];
    console.log(this.groupmembers);
  }

  selectreserve(){

    window.scrollTo(0, 0)

    this.reserveselection = true;
    this.partnerselection = false;
    this.slotselection = false;
  }

  selectpartner()
  {
    this.reserveselection = false;
    this.partnerselection = true;
    this.slotselection = false;
    this.getGroups(1);
  }

  selectslot(){
    this.reserveselection = false;
    this.partnerselection = false;
    this.slotselection = true;
    this.courtselection = false;
    this.getTimeSlot();
  }

  selectcourt(){
    this.reserveselection = false;
    this.partnerselection = false;
    this.slotselection = false;
    this.courtselection = true;
    this.getCourts();
  }

  getGroups(type){

    window.scrollTo(0, 0)

    this.spinnerService.show();

    if(type==1){
      this.players = 2;
    }
    if(type==2){
      this.players = 4;
    }

    console.log("players: " + this.players);


    this.active = true;
    this.partners = type;
    
    if(this.reserve == 'single')
    {
      this.mygroups = [];
      this.active = false;
      return;
    }


    this.servicedata = {
      member_id :this.m_id,
      type:type
    }

    this.common.serverdatapost('getMemberGroup',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data.response);
            this.mygroups = this.data.response;
            this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }
  
  getTimeSlot(){

    window.scrollTo(0, 0)

    this.spinnerService.show();
    this.active = true;

    this.servicedata = {
      type: this.partners
    }
    this.common.serverdatapost('getCourtTime',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data.response);
            this.timeslot = this.data.response;
            this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }

  getCourts(){

    window.scrollTo(0, 0)

    this.spinnerService.show();
    this.active = true;
    this.myfbcourtid = "";

    this.servicedata = {
      from: this.fromtime
    }
    this.common.serverdatapost('getCourtByTime',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data.response);
            var fbcourt = this.data.response;
            for (let i = 0; i < fbcourt.length; i++) {
              if(fbcourt[i].members.length<this.players && fbcourt[i].players == this.players) 
              {
                fbcourt[i].myspace = true;
                console.log(fbcourt);
                this.fbcourts = fbcourt;
                break;
              }
              // else
              // {
              //   fbcourt[i].myspace = false;
              // }

              if(i == (fbcourt.length - 1))
              {
                console.log(fbcourt);
                this.fbcourts = fbcourt;
              }
            }
            this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }


  selectmyfbcourt(cid,ctid,length,fit,space)
  {
    console.log(length);
    console.log(this.partners);
    console.log(this.players);
    console.log(this.groupmembers);

    this.timeid = ctid;

    // singles
    if(this.partners == 1 && fit == 2)
    {
      if(this.reserve == 'single')
      {
        if(length < 2 && space)
        {
          this.myfbcourtid = cid;
          this.active = false;    
        }
      }
      if(this.reserve == 'group')
      {
        if(length == 0)
        {
          this.myfbcourtid = cid;
          this.active = false;    
        }
      }
    }

    // doubles
    if(this.partners == 2 && fit == 4)
    {
      if(this.reserve == 'single')
      {
        if(length < 4 && space)
        {
          this.myfbcourtid = cid;
          this.active = false;    
        }
      }
      if(this.reserve == 'group')
      {
        if(length == 0)
        {
          this.myfbcourtid = cid;
          this.active = false;    
        }
      }
    }

    // if(length < this.partners){
    //   this.myfbcourtid = cid;
    //   this.active = false;    
    // }

  }

  reserveslot(){

    this.active = true;
    this.servicedata = {
      court_id:this.myfbcourtid,
      court_time_id: this.timeid,
      member_no:this.groupmembers
    }
    this.common.serverdatapost('addCourtBooking',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data);
            
            if(this.data.status == true){
              this.successMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('successModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('successModal').style.display = "none";
              },3000);  
            }
            
            if(this.data.status == false){

              this.errorMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "none";
              },3000);
            }

            this.router.navigate(['dashboard'])
            .then(() => {
              window.location.reload();
            });
      },
      errors => {
        console.log(errors);
        let err = errors;
        
        this.errorMessage = err.message;
        setTimeout( () => {
          document.getElementById('errorModal').style.display = "block";
        },500);
        setTimeout( () => {
          document.getElementById('errorModal').style.display = "none";
        },3000);
        
      }
    )

  }

}
