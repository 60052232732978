import { Component, OnInit } from '@angular/core';
import{Router} from '@angular/router';
import { CommonService } from '../service/common.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';



@Component({
  selector: 'app-create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css']
})
export class CreateBlogComponent implements OnInit {
  public Editor = ClassicEditor;
  banner:any='';
  title: any;
  description: any;
  content: any;
  author: any;
  data:any;
  tag:any;
  mid:any;
  id:any;
  btnStatus:any='create';
  allblogs:any=[];
  servicedata:any=[];
  blogId:any;
  active:any=false;

 



  constructor(private route:Router,private common:CommonService) { 
    
  }

  ngOnInit() {
    
      if(localStorage.getItem('blog')){
        this.btnStatus = 'update'
        let blog = JSON.parse(localStorage.getItem('blog'))
        this.blogId=blog.id,
        this.title = blog.title,
        this.description = blog.description,
        this.author = blog.author,
        this.content = blog.content,
        this.tag = blog.tag,
        this.banner = blog.banner
        console.log(blog)
      }
  }

  navigate(){
    localStorage.removeItem('blog');
    this.route.navigate(['/manage-blog']);
  }
  public onChange( { editor }: ChangeEvent ) {
    this.content = editor.getData();
    console.log(this.content)
  }


 

 
  getverifiedblog() {
    this.mid = localStorage.getItem("memberId");
    console.log(this.mid);
    this.common.serverdata("getBlogByMemberId?member_id=" + this.mid, []).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status == true) {
          this.allblogs = this.data.result;
          console.log(this.allblogs);
        }
      },
      err => {
        console.log(err);
      }
    );
  }



  uploadBanner(event) {
    this.banner = event.target.files[0]
    let size = this.banner.size/1024;
    if(size>500){
        this.banner = ''
        alert("File size must be less than 500 KB");
    }
  }

  createblog() {
      this.active=true;
      var formdata = new FormData();
      formdata.append("title", this.title),
      formdata.append("description", this.description),
      formdata.append("content", this.content),
      formdata.append("author", this.author),
      formdata.append("banner", this.banner),
      formdata.append("member_id", localStorage.getItem("memberId")),
      formdata.append("tag", this.tag)

    this.common.serverdatapost("addBlog", formdata).subscribe(
      (res) => {
        this.data = res;
        console.log(this.data);
        localStorage.removeItem('blog');
        if (this.data.status == true) {
          this.active=false;
          this.getverifiedblog();
          alert("your blog has been successfully created");
          this.route.navigate(['/manage-blog']);

        }

      },
      (err) => {
        console.log(err);
        localStorage.removeItem('blog');

      }
    );
  }

  clear() {
    this.title = "",
      this.description = "",
      this.author = "",
      this.content = "";
      this.tag = '',
      this.banner = ''
  }
 
   // //update blog
  updateblog() {
    this.servicedata = {
      "title": this.title,
      "description": this.description,
      "author": this.author,
      "content": this.content,
      "member_id": localStorage.getItem("memberId"),
      "blog_id": this.blogId,
      "banner": this.banner,
      "tag": this.tag
    }
    console.log(this.servicedata)
    this.common.serverdatapost("updateBlog", this.servicedata).subscribe(
      (res) => {
        this.data = res;
        console.log(this.data);
        localStorage.removeItem('blog');
        if (this.data.status) {
          this.getverifiedblog();
          this.clear();
          alert("your blog has been successfully updated");
          this.route.navigate(['/manage-blog']);

        }

      },
      err => {
        localStorage.removeItem('blog');
        console.log(err);
      }
    );
  }



}
