import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-fba-rules',
  templateUrl: './fba-rules.component.html',
  styleUrls: ['./fba-rules.component.css']
})
export class FbaRulesComponent implements OnInit {

  constructor(private common: CommonService,private spinnerService: Ng4LoadingSpinnerService) { }

  datas:any = []
  rules:any = []
  ngOnInit() {
    this.getFbaRules()
  }


  getFbaRules() {

    this.spinnerService.show();

    this.common.serverdata('getWebsiteSetting?page=fba-rules', '').subscribe(
      res => {
        this.datas = res;
        console.log(this.datas)
        this.rules = this.datas.response
        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }

}
