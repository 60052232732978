import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-blog',
  templateUrl: './manage-blog.component.html',
  styleUrls: ['./manage-blog.component.css']
})
export class ManageBlogComponent implements OnInit {

  data: any;
  email: any;
  servicedata: any;
  first_name: any;
  last_name: any;
  mobile: any;
  m_id: any;
  mid: any;
  tag: any;

  member_no: any;
  old_password: any;
  new_password: any;
  cpassword: any;

  successMessage: any;
  errorMessage: any;
  allblogs: any;
  title: any;
  description: any;
  content: any;
  author: any;
  id: any;
  value: boolean = false;
  banner: any = '';

  constructor(private common: CommonService, private spinnerService: Ng4LoadingSpinnerService, private route: Router) {
    this.data = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    this.member_no = this.data;
  }

  ngOnInit() {
    this.getUserProfile();
    this.getverifiedblog();
    // editor
  }

  getUserProfile() {
    this.spinnerService.show();
    this.servicedata = {
      member_id: this.data,
      email: this.email,
    }

    this.common.serverdatapost('getMemberProfile?member_number=' + this.data, this.servicedata).subscribe(
      res => {
        this.data = res;
        console.log(this.data.response);
        let userProfile = this.data.response;
        this.first_name = userProfile.first_name;
        this.last_name = userProfile.last_name;
        this.email = userProfile.email;
        this.mobile = userProfile.mobile;
        this.m_id = userProfile.member_no;
        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }


  updatePassword() {

    this.spinnerService.show();

    this.servicedata = {
      member_no: this.member_no,
      old_password: this.old_password,
      new_password: this.new_password,
      conf_password: this.cpassword
    }

    this.common.serverdatapost('changeMemberPassword', this.servicedata).subscribe(
      res => {
        this.data = res;
        alert(this.data.message);

        if (this.data.status == true) {
          this.successMessage = this.data.message;
          setTimeout(() => {
            document.getElementById('successModal').style.display = "block";
          }, 500);
          setTimeout(() => {
            document.getElementById('successModal').style.display = "none";
          }, 3000);
        }

        if (this.data.status == false) {

          this.errorMessage = this.data.message;
          setTimeout(() => {
            document.getElementById('errorModal').style.display = "block";
          }, 500);
          setTimeout(() => {
            document.getElementById('errorModal').style.display = "none";
          }, 3000);
        }

        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }

  getverifiedblog() {
    this.mid = localStorage.getItem("memberId");
    console.log(this.mid);
    this.common.serverdata("getBlogByMemberId?member_id=" + this.mid, []).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status == true) {
          this.allblogs = this.data.result;
          console.log(this.allblogs);
        }
      },
      err => {
        console.log(err);
      }
    );
  }



  deleteblog(id) {
    console.log(id);
    this.servicedata = {
      blog_id: id
    }
    this.common.serverdatapost("deleteBlog", this.servicedata).subscribe(
      (res) => {
        this.data = res;
        console.log(this.data);
        if (this.data.status == true) {
          this.getverifiedblog();
        }
      },
      (err) => {
        console.log(err);

      }
    );
  }

  editblog(blogs) {
    console.log(blogs);
    localStorage.setItem('blog', JSON.stringify(blogs))
    this.route.navigate(['/create-blog']);

  }




  navigate(id) {
    this.route.navigate(['/blogdetail', id]);
  }
  createnewblog() {
    this.route.navigate(['/create-blog']);
  }
}

