import { Component, OnInit } from '@angular/core';

interface News {
  title: string;
  date: string;
  imageUrl?: string;
}

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.css']
})
export class LatestNewsComponent implements OnInit {
  businessNews: News[] = [
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' }
  ];

  youthNews: News[] = [
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/350x150' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' },
    { title: 'What happens to factuality itself as algorithms replace humans', date: '2017-09-19', imageUrl: 'http://placehold.it/150' }
  ];

  constructor() { }

  ngOnInit(): void {
  }
}
