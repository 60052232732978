import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  error:any = "";
  servicedata:any;
  data:any;

  max=30;
  current=0;

  msmax=0;
  mscurrent=0;

  plan:any = "MY PLAN";

  plantype:any = "...";

  planmsg:any = "...";

  planstatus:any = "";

  childprofile:any = [];

  member_reserve_court:any = [];


  email:any;
  first_name:any;
  last_name:any;
  mobile:any;
  m_id:any;
  profileimage:any = 'assets/default-profile.png';

  constructor(private common:CommonService, private router: Router) {
    this.data = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    if(!this.data) this.router.navigateByUrl('/login');
   }

  ngOnInit() {
    this.getUserProfile();
    this.getReserveCourt(this.data);
  }

  getUserProfile(){
        this.servicedata = {
          member_id:this.data,
          email:this.email,
        }
      
      this.common.serverdatapost('getMemberProfile?member_number='+this.data,this.servicedata).subscribe(
        res => {
              this.data = res;
              console.log(this.data.response);
              let userProfile = this.data.response;
              this.first_name = userProfile.first_name;
              this.last_name = userProfile.last_name;
              this.email = userProfile.email;
              this.mobile = userProfile.mobile;
              this.m_id = userProfile.id;
              this.profileimage = userProfile.profile;

              this.plan = this.data.response.membership_plan;
              this.plantype = this.data.response.membership_type;
              this.planmsg = this.data.response.plan_msg;
              this.planstatus = this.data.response.status;

              this.getplan(this.data.response.id);
              this.getchildprofile(this.data.response.id);
        },
        errors => {
          console.log(errors);
        }
      )
  }


  getplan(id)
  {
    this.servicedata = {
      member_id: id
    }
                  
    this.common.serverdatapost("getMemberPlan",this.servicedata).subscribe(
      res => {

          this.data=res;
          console.log(this.data);
        
          if(this.data.status==true)
          {
            this.msmax = this.data.response.total_day;
            this.mscurrent = this.data.response.used_day;
          }
          else
          {
            console.log("error");
          }

      },
      error => {
        console.log(error);
      }
    )

  }


  getchildprofile(id)
  {

    this.servicedata = {
      member_id: id
    }   
                  
    this.common.serverdatapost("getChildMember",this.servicedata).subscribe(
      res => {
          this.data=res;
          console.log(this.data);
        
          if(this.data.status==true)
          {
            this.childprofile = this.data.response;
          }
          else
          {
            console.log("error");
          }
          
      },
      error => {
        console.log(error);
      }
    )

  }

  getReserveCourt(mno)
  {

    this.servicedata = {
      member_no: mno
    }   
                  
    this.common.serverdatapost("getCourtBookingDetails",this.servicedata).subscribe(
      res => {
          this.data=res;
          console.log(this.data);
        
          if(this.data.status==true)
          {
            this.member_reserve_court = this.data.response;
          }
          else
          {
            console.log("error");
          }
          
      },
      error => {
        console.log(error);
      }
    )

  }


}
