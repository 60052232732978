import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.css']
})
export class GroupComponent implements OnInit {

  member_id:any;
  data:any;
  email:any;
  servicedata:any;
  first_name:any;
  last_name:any;
  mobile:any;
  m_id:any;
  profileimage:any = 'assets/default-profile.png';
  twoplayer:boolean = false;
  fourplayer:boolean = false;
  select:boolean = true;
  member1:any;
  member2:any;
  member3:any;
  member4:any;
  name:any;
  type:any;
  mygroups:any = [];
  groupid:any;
  hide:boolean = true;

  successMessage:any;
  errorMessage:any;

  constructor(private common:CommonService, private router: Router,private spinnerService: Ng4LoadingSpinnerService) {
    this.member_id = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    if(!this.member_id) this.router.navigateByUrl('/login');
   }

  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile(){
        this.spinnerService.show();

        this.servicedata = {
          member_id:this.member_id,
          email:this.email,
        }
      
      this.common.serverdatapost('getMemberProfile?member_number='+this.member_id,this.servicedata).subscribe(
        res => {
              this.data = res;
              console.log(this.data.response);
              let userProfile = this.data.response;
              this.first_name = userProfile.first_name;
              this.last_name = userProfile.last_name;
              this.email = userProfile.email;
              this.mobile = userProfile.mobile;
              this.m_id = userProfile.id;
              this.profileimage = userProfile.profile;
              this.member1 = userProfile.member_no;
              this.getAllGroups();
              this.spinnerService.hide();
        },
        errors => {
          console.log(errors);
          this.spinnerService.hide();
        }
      )
  }


  selectPlayer(number){

    // this.member1 = this.m_id;
    if(number == 1){
      this.type = 1;
      this.select = false;
      this.twoplayer = true;
      this.fourplayer = false;
    }
    if(number == 2){
      this.type = 2;
      this.select = false;
      this.twoplayer = false;
      this.fourplayer = true;
    }
  }

  backbtn(){
    this.select = true;
    this.twoplayer = false;
    this.fourplayer = false;
  }

  getAllGroups(){

    this.spinnerService.show();
    this.servicedata = {
      member_id :this.m_id
    }

    this.common.serverdatapost('getAllMemberGroup',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data.response);
            this.mygroups = this.data.response;
            this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }

  AddPlayer(){

    this.spinnerService.show();
    this.servicedata = {
      member_id:this.m_id,
      name:this.name,
      type:this.type,
      member_no1:this.member1,
      member_no2:this.member2,
      member_no3:this.member3,
      member_no4:this.member4,
    }

    this.common.serverdatapost('addMemberGroup',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data);
            
            
            if(this.data.status == true){
              this.successMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('successModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('successModal').style.display = "none";
              },3000);  
            }
            
            if(this.data.status == false){

              this.errorMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "none";
              },3000);
            }

            this.getAllGroups();
            this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }

  update(g_id){

    this.hide = false;
    let mygroup = this.mygroups.find( e => (e.id == g_id))
    console.log(mygroup.id);

    this.groupid = mygroup.id;
    this.name = mygroup.name;
    this.member1 = mygroup.member_no1;
    this.member2 = mygroup.member_no2;
    this.member3 = mygroup.member_no3;
    this.member4 = mygroup.member_no4;

    if(mygroup.type == 1){
      this.type = 1;
      this.select = false;
      this.twoplayer = true;
      this.fourplayer = false;
    }
    if(mygroup.type == 2){
      this.type = 2;
      this.select = false;
      this.twoplayer = false;
      this.fourplayer = true;
    }

  }

  updatePlayer(){


    this.spinnerService.show();

    this.servicedata = {
      member_id:this.m_id,
      name:this.name,
      type:this.type,
      member_no1:this.member1,
      member_no2:this.member2,
      member_no3:this.member3,
      member_no4:this.member4,
      id:this.groupid
    }

    this.common.serverdatapost('updateMemberGroup',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data);

            if(this.data.status == true){
              this.successMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('successModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('successModal').style.display = "none";
              },3000);  
            }
            
            if(this.data.status == false){

              this.errorMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "none";
              },3000);
            }


            this.getAllGroups();
            this.spinnerService.hide();

            this.select = true;
            this.twoplayer = false;
            this.fourplayer = false;
            this.hide = true;
            
            this.name = '';
            this.type = '';
            // this.member1 = '';
            this.member2 = '';
            this.member3 = '';
            this.member4 = '';
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }

  deletePlayer(g_id){
    this.spinnerService.show();

    this.servicedata = {
      id:g_id,
    }

    this.common.serverdatapost('deleteMemberGroup',this.servicedata).subscribe(
      res => {
            this.data = res;
            console.log(this.data);
         
            if(this.data.status == true){
              this.successMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('successModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('successModal').style.display = "none";
              },3000);  
            }
            
            if(this.data.status == false){

              this.errorMessage = this.data.message;
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "block";
              },500);
              setTimeout( () => {
                document.getElementById('errorModal').style.display = "none";
              },3000);
            }

            this.getAllGroups();
            this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }


  clearform()
  {
    this.select = true;
    this.twoplayer = false;
    this.fourplayer = false;
    
    this.name = '';
    this.type = '';
    this.member2 = '';
    this.member3 = '';
    this.member4 = '';
  }

}
