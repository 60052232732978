import { Component, ViewChild,OnInit, ElementRef } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'app-membership-contract',
  templateUrl: './membership-contract.component.html',
  styleUrls: ['./membership-contract.component.css']
})
export class MembershipContractComponent implements OnInit {

  @ViewChild ('Signature',{static:false}) signaturePad: SignaturePad;
  @ViewChild('profileImage',{static:false}) profileImage:ElementRef;

  signaturePadOptions: Object = { 
    'minWidth': 2,
    'canvasWidth': 300,
    'canvasHeight': 100
  };

  signatureImg:any = '';
  agreement: any = true;
  active: boolean = true;
  levelselection: any = false;
  servicedata: any;
  data: any;

  emailId: any = '';
  primaryContactNumber: any = '';
  firstName: any = '';
  lastName: any = '';
  emergencyContactName: any = '';
  emergencyContactPhone: any = '';
  alternateContactNumber: any = '';
  membership_plan: any = '';
  membership_type: any = '';
  homeAddress: any = '';
  city: any = '';
  state: any = '';
  zipCode: any = '';
  family: any = '';
  selectfamily: any = false;
  showForm: any = false;
  Profile: any = '';


  family_member: any = '';
  email_family: any = '';
  familyfirstName: any = '';
  familylastName: any = '';
  ContactNumber: any = '';
  contract_id: any = '';
  contract_family_id: any = '';

  family_details: any = [];

  successMessage: any = '';
  errorMessage: any = '';
  errors: any = {};

  btnactive: any = true;
  profile: any = '';

  constructor(private common: CommonService, private router: Router,private spinnerService: Ng4LoadingSpinnerService) { }
  ngOnInit() {
  }
  ngAfterViewInit() {
    // this.signaturePad is now available
    if(this.signaturePad){
      this.signaturePad.set('minWidth', 2); 
      this.signaturePad.clear(); 
    }
    
  }
  drawComplete() {
    console.log(this.signaturePad.toDataURL());
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureImg='';
  }

  
  


  selectagree() {
    this.active = false;
  }

  agree() {

    window.scrollTo(0, 0)

    this.agreement = true;
    this.levelselection = false;
    this.active = true;
  }

  selectlevel() {
    window.scrollTo(0, 0)

    this.levelselection = true;
    this.agreement = false;
    this.selectfamily = false;
    this.showForm = false;
  }



  // check(){
  //   if( this.family_member == '' ||
  //       this.email_family == '' ||
  //       this.familyfirstName == '' ||
  //       this.familylastName == '' ||
  //       this.ContactNumber == '')
  //   {
  //       this.btnactive = true;
  //   }
  //   else{
  //     this.btnactive = false;
  //   }
  // }

  uploadFamilyProfile(event) {
    this.profile = event.target.files[0]
  }

  addFamilyMember() {
    this.errors = [];
    var formdata = new FormData()
    formdata.append("firstName", this.familyfirstName)
    formdata.append("lastName", this.familylastName)
    formdata.append("email", this.email_family)
    formdata.append("familyRelation", this.family_member)
    formdata.append("contactNumber", this.ContactNumber)
    formdata.append("profile", this.profile)
    formdata.append("contract_id", this.contract_id)


    this.common.serverdatapost('addContractFamilyMembers', formdata).subscribe(
      res => {

        this.data = res;
        console.log(this.data);
        if (this.data.status) {

          this.getContractFamilyMembers();
          this.clearfamilydetails();
        }
        else {
          this.errors = this.data.result;

        }
      },
      err => {
        console.log(err);
      }
    );
  }
  clearfamilydetails() {
    this.familyfirstName = '',
      this.familylastName = '',
      this.email_family = '',
      this.family_member = '',
      this.ContactNumber = '',
      // this.profile=''
      this.profileImage.nativeElement.value=''
  }

  getContractFamilyMembers() {
    this.common.serverdata("getContractFamilyMembers?contract_id=" + this.contract_id, []).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status) {
          this.family_details = this.data.result;
          console.log(this.family_details)
        }
      },
      err => {
        console.log(err)
      }
    );
  }

  deleteContractFamilyMember(contract_family_id) {
    var formdata = new FormData();
    formdata.append("contract_family_id", contract_family_id)

    this.common.serverdatapost("deleteContractFamilyMember", formdata).subscribe(
      res => {
        console.log(this.data);
        if (this.data.status) {
          this.getContractFamilyMembers();
        }
      },
      err => {
        console.log(err)
      }
    );
  }

  enroll() {
    this.spinnerService.show();
    this.errors = [];

    window.scrollTo(0, 0)


    const formData: FormData = new FormData();


    formData.append('Email', this.emailId);
    formData.append('FirstName', this.firstName);
    formData.append('LastName', this.lastName);
    formData.append('PrimaryContactNumber', (this.primaryContactNumber)?this.primaryContactNumber:'');
    formData.append('AlternateContactNumber', (this.alternateContactNumber)?this.alternateContactNumber:'');
    formData.append('EmergencyContactName', this.emergencyContactName);
    formData.append('EmergencyContactNumber',(this.emergencyContactPhone)?this.emergencyContactPhone:'');
    formData.append('MembershipPlan', this.membership_plan);
    formData.append('MembershipType', this.membership_type);
    formData.append('FamilyMembers', JSON.stringify(this.family_details));
    formData.append('Address', this.homeAddress);
    formData.append('City', this.city);
    formData.append('State', this.state);
    formData.append('ZipCode', this.zipCode);
    formData.append('Agree', 'yes');
    formData.append('From', 'Website');
    formData.append('Type', '1');
    formData.append('Profile', this.profile);
    formData.append('Signature', this.signatureImg);

    if (!(this.membership_type == 'Adult' || this.membership_type == 'Junior (under 18)'))
      formData.append('Status', 'archive');

    this.common.serverdatapost('addContractService2', formData).subscribe(
      
      res => {

        this.data = res;
        this.spinnerService.hide();
        

        console.log(this.data);

        if (this.data.status) {
          
          console.log(this.data.result[0]);
          this.contract_id = this.data.result[0].id;
          console.log(this.contract_id);
          if (this.membership_type == 'Adult' || this.membership_type == 'Junior (under 18)') {

            this.successMessage = this.data.message;
            setTimeout(() => {
              document.getElementById('successModal').style.display = "block";
            }, 500);
            setTimeout(() => {
              document.getElementById('successModal').style.display = "none";
              this.router.navigateByUrl("/");
            }, 3000);
        
          }
          else {
            console.log('hereeee in else')
            this.levelselection = false;
            this.showForm = true;
            this.successMessage = this.data.message;


          }
        }
        else {
          this.errors = this.data.result;
        }

      },
      errors => {
        console.log(errors);
      }
    )
  }

  profileSrc: any = "";

  uploadProfile(str: any) {
    this.profile = str.target.files[0];
    console.log(this.profile)
    if (this.profile) {
      const reader = new FileReader();
      reader.onload = e => this.profileSrc = reader.result;
      reader.readAsDataURL(this.profile);
    }



  }

  enrollwithfamily() {
    setTimeout(() => {
      document.getElementById('successModal').style.display = "block";
    }, 500);
    setTimeout(() => {
      document.getElementById('successModal').style.display = "none";
      this.router.navigateByUrl("/");
    }, 3000);

  }


}
