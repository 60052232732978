import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chessclass',
  templateUrl: './chessclass.component.html',
  styleUrls: ['./chessclass.component.css']
})
export class ChessclassComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
