import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { NgZone } from '@angular/core';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private common: CommonService, private ngZone: NgZone, private route: Router) {

  }

  blogs: any[];
  formatDate(value) {
    if (value) {
      return moment(value).format('MM/DD/YYYY');
    }
  }

  data: any = [];
  news: any = [];
  reflection: any[]
  content: any = '';
  divInView = false;
  allcoaches: any = [];
  status: boolean = false;
  allachievement: any = [];
  loader: boolean = true;

  limit: number[] = [300, 300, 300, 300, 300, 300, 300, 300, 300];

  isCollapsed = [true, true, true, true, true, true, true, true, true];
  images: string[] = [
    "assets/images/new_gallery/austin_img (1).jpeg",
    "assets/images/new_gallery/austin_img (2).jpg",
    "assets/images/new_gallery/austin_img (3).jpg",
    "assets/images/new_gallery/austin_img (4).jpg",
    "assets/images/new_gallery/austin_img (5).jpg",
    "assets/images/new_gallery/austin_img (6).jpg",
    "assets/images/new_gallery/austin_img (7).jpg",
    "assets/images/new_gallery/austin_img (8).jpg",
    "assets/images/new_gallery/austin_img (9).jpg",
    "assets/images/new_gallery/austin_img (10).jpg",
    "assets/images/new_gallery/austin_img (11).jpeg",
    "assets/images/new_gallery/austin_img (12).jpg",
    "assets/images/new_gallery/fbq1.jpg",
    // "assets/images/new_gallery/fbq2.jpg",
    "assets/images/new_gallery/fbq3.jpg",
    // "assets/images/new_gallery/fbq4.jpg",
    // "assets/images/new_gallery/fbq5.jpg",
    "assets/images/new_gallery/fbq6.jpg",
    "assets/images/new_gallery/fbq7.jpg",
    "assets/images/new_gallery/fbq8.jpg",
    "assets/images/new_gallery/fbq9.jpg",
    "assets/images/new_gallery/fbq10.jpg",
    "assets/images/new_gallery/fbq11.jpg",
    "assets/images/new_gallery/fbq12.jpg",
    // "assets/images/new_gallery/fbq13.jpg",
    // old
    // "assets/images/46878259_2376618399032779_1913032077779927040_n.jpg",
    "assets/images/48372630_2404374576257161_3385075566649540608_n.jpg",
    // "assets/images/60484598_2439414762788212_7658188595873710080_o.jpg",
    "assets/images/72086188_2707590422637310_2049335819855986688_o (1).jpg",
    "assets/images/72485292_2707591289303890_39412681492398080_o.jpg",
    // "assets/images/72616896_2707589792637373_5204373247369412608_o.jpg",
    "assets/images/72691662_2700481550014864_663170279379304448_o.jpg",
    // "assets/images/72112081_2700481980014821_128372165840994304_o.jpg"
  ]

  toggleView(id) {
    this.isCollapsed[id] = !this.isCollapsed[id];
    if (this.limit[id] < 400) {
      this.limit[id] = 1000000;

    } else {
      this.limit[id] = 300
    }
  }


  ngOnInit() {
    this.getAchievements();
    this.getblogs();
    this.currentSlide('1');
    this.openModel();
    this.getcoaches();

    const tabs = document.querySelectorAll('[data-tab-target]') as NodeListOf<HTMLElement>;
    const tabContents = document.querySelectorAll('[data-tab-content]') as NodeListOf<HTMLElement>;

    tabs.forEach(tab => {
      tab.addEventListener('click', () => {
        const target = document.querySelector(tab.dataset.tabTarget);
        tabContents.forEach(tabContent => {
          tabContent.classList.remove('active')
        })
        tabs.forEach(tab => {
          tab.classList.remove('active')
        })
        tab.classList.add('active')
        target.classList.add('active')
      })
    })
  }

  read() {
    this.status = !this;
  }

  navigate(id) {
    console.log(id);
    this.route.navigate(['/blogdetail', id]);
  }

  openModel() {
    this.common.serverdata('getWebsiteSetting?page=home&section=notice-popup', []).subscribe(
      res => {
        this.data = res
        console.log(this.data)
        this.content = this.data.response[0].dataset[0].value;
        document.getElementById("openModalButton").click();
      },
      errors => {
        console.log(errors);
      }
    )
  }

  closeVideo() {
    var iframe = <HTMLIFrameElement>document.getElementById('myframe');

    var source = iframe.src;
    iframe.src = '';
    setTimeout(() => {
      iframe.src = source;
    }, 5000);

  }

  currentSlide(val) {
    console.log(val);
    if (val == '1') {
      document.getElementById('1').style.display = 'block';
      document.getElementById('2').style.display = 'none';
      document.getElementById('3').style.display = 'none';
      document.getElementById('a').className += " active";
      document.getElementById('b').classList.remove("active");
      document.getElementById('c').classList.remove("active");
    }
    if (val == '2') {
      document.getElementById('2').style.display = 'block';
      document.getElementById('1').style.display = 'none';
      document.getElementById('3').style.display = 'none';
      document.getElementById('b').className += " active";
      document.getElementById('a').classList.remove("active");
      document.getElementById('c').classList.remove("active");
    }
    if (val == '3') {
      document.getElementById('3').style.display = 'block';
      document.getElementById('1').style.display = 'none';
      document.getElementById('2').style.display = 'none';
      document.getElementById('c').className += " active";
      document.getElementById('a').classList.remove("active");
      document.getElementById('b').classList.remove("active");
    }


  }

  hideGtc() {
    document.getElementById('gtc').style.display = 'none'
  }

  getcoaches() {
    this.common.serverdata('getCoaches', []).subscribe(
      res => {
        this.data = res;
        this.allcoaches = this.data.result;
        console.log(this.allcoaches);

      },
      err => {
        console.log(err)
      }
    )
  }

  getAchievements() {
    this.common.serverdatafrisco('getAchievements', []).subscribe(
      res => {
        this.data = res;
        this.loader = !this.loader
        this.allachievement = this.data.result.reverse();
        this.reflection = [this.allachievement[0], this.allachievement[1], this.allachievement[2], this.allachievement[3]];
        console.log(this.reflection)

      },
      err => {
        console.log(err)
      }
    )
  }

  getblogs() {
    let url = "getPublishedBlog";
    console.log("blogssssss");

    if (localStorage.getItem("isLoggedIn") == "yes")
      url = "getPublishedBlog?member_id=" + localStorage.getItem("memberId")
    this.common.serverdatafrisco(url, []).subscribe(
      res => {
        this.data = res;
        console.log("blogssssss", this.data.result);
        if (this.data.status == true) {
          this.blogs = this.data.result.reverse();
          this.news = [this.blogs[0], this.blogs[1], this.blogs[2], this.blogs[3], this.blogs[4], this.blogs[5]]
          console.log(this.news);
        }
      },
      err => {
        console.log(err);
      }
    );

  }

}
