import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-upcoming',
  templateUrl: './upcoming.component.html',
  styleUrls: ['./upcoming.component.css']
})
export class UpcomingComponent implements OnInit {

  data:any = []
  camp_flyer:any = ''
  constructor(private common: CommonService) { 
    this.getWebsiteSetting()
  }

  ngOnInit() {
  }

  getWebsiteSetting(){

    this.common.serverdata('getWebsiteSetting?page=upcoming&section=camp-flyer',[]).subscribe(
      res => {
          this.data = res;
          console.log(this.data)

          this.camp_flyer = this.data.response[0].dataset[0].value
      },
      errors => {
          console.log(errors);
      }
    )
    
  }

}
