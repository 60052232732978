import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';



@Component({
  selector: 'app-blogdetail',
  templateUrl: './blogdetail.component.html',
  styleUrls: ['./blogdetail.component.css']
})
export class BlogdetailComponent implements OnInit {
  id: any;
  data: any;
  bdetails: any = [];
  servicedata:any=[];
  comment:any;
  allcomments:any=[];
  member:any;

  constructor(private route: ActivatedRoute, private common: CommonService,) {
    this.route.paramMap.subscribe(params => {
      this.id = params.get("id")
      console.log(this.id);

    })
  }

  ngOnInit() {
    this.getblogs(this.id);
    // this.getBlogComment();

  }

  getblogs(id) {
    this.common.serverdatafrisco("getBlogById?blog_id=" + id, []).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status == true) {
          
          this.bdetails = this.data.result;
          console.log(this.bdetails);
          this.member=this.bdetails.memberdata;
          console.log(this.member);
          console.log(this.bdetails.member_id);
          var date = new Date(this.bdetails.created_at);

        }
      },
      err => {
        console.log(err);
      }

    );
  }

  // addBlogComment(){
  //   this.servicedata={
  //     "blog_id":this.id,
  //     "member_id":this.bdetails.member_id,
  //     "comment":this.comment,
  //   }
  //   this.common.serverdatapost("addBlogComment",this.servicedata).subscribe(
  //     res=>{
  //       this.data=res;
  //       console.log(this.data);
  //       if(this.data.status){
  //         this.getBlogComment();
  //         this.getblogs(this.id);
  //         this.clearBlogComment();
  //       }
  //     },
  //     err=>{
  //       console.log(err);
  //     }
  //   );
  // }

  // getBlogComment(){
  //   this.common.serverdata("getBlogComment?blog_id=" +this.id,[]).subscribe(
  //     res=>{
  //       this.data=res;
  //       console.log(this.data);
  //       if(this.data.status){
  //         this.allcomments=this.data.result;
  //         console.log(this.allcomments);
  //       }
  //     },
  //     err=>{
  //       console.log(err);
  //     }
  //   );
  // }

  // clearBlogComment(){
  //   this.comment='';

  // }

}
