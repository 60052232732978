import { Component, OnInit,ViewChild } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SignaturePad } from 'angular2-signaturepad';


@Component({
  selector: 'app-trainingclass',
  templateUrl: './trainingclass.component.html',
  styleUrls: ['./trainingclass.component.css']
})
export class TrainingclassComponent implements OnInit {

  @ViewChild ('Signature',{static:false}) signaturePad: SignaturePad;
  signaturePadOptions: Object = { 
    'minWidth': 2,
    'canvasWidth': 300,
    'canvasHeight': 100
  };


  signatureImg:any='';
  member_id: any='';
  data: any='';
  email: any='';
  servicedata: any='';
  first_name: any='';
  last_name: any='';
  mobile: any='';
  m_id: any='';
  profileimage: any = 'assets/default-profile.png';
  studentLevel: any = []
  student_levels_id: any='';
  class_time: any='';
  selectedClass: any = [];
  studentlevel: any='';
  agreement: any = true;
  active: boolean = true;

  levelselection: boolean = false;
  slotselection: boolean = false;
  myform: boolean = false;

  emailId: any='';
  schoolGrade: any='';
  firstName: any='';
  lastName: any='';
  emergencyContactName: any='';
  emergencyContactPhone: any='';
  fatherFirstName: any='';
  fatherLastName: any='';
  motherFirstName: any='';
  motherLastName: any='';
  fatherContactNumber: any='';
  homeAddress: any='';
  city: any='';
  state: any='';
  zipCode: any='';

  monday: any = '';
  tuesday: any = '';
  wednesday: any = '';
  thursday: any = '';
  friday: any = '';
  saturday: any = '';
  sunday: any = '';

  successMessage:any;
  errorMessage:any;
  profile: any = '';
  errors: any = {};
  class:any=[];

  constructor(private common: CommonService, private router: Router,private spinnerService: Ng4LoadingSpinnerService) 
  {
    this.member_id = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    // if(!this.member_id) this.router.navigateByUrl('/login');
  }

  ngOnInit() {
    this.getUserProfile();
  }
  ngAfterViewInit() {
    // this.signaturePad is now available
    if(this.signaturePad){
      this.signaturePad.set('minWidth', 2); 
      this.signaturePad.clear(); 
    }
    
  }
  drawComplete() {
    console.log(this.signaturePad.toDataURL());
    const base64Data = this.signaturePad.toDataURL();
    this.signatureImg = base64Data;
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureImg='';
  }


  getUserProfile() {
    this.spinnerService.show();

    this.servicedata = {
      member_id: this.data,
      email: this.email,
    }

    this.common.serverdatapost('getMemberProfile?member_number=' + this.member_id, this.servicedata).subscribe(
      res => {
        this.data = res;
        console.log(this.data.response);
        let userProfile = this.data.response;
        this.first_name = userProfile.first_name;
        this.last_name = userProfile.last_name;
        this.email = userProfile.email;
        this.mobile = userProfile.mobile;
        this.m_id = userProfile.id;
        this.profileimage = userProfile.profile;
        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }

  getStudentLevel() {
    window.scrollTo(0, 0);

    this.spinnerService.show();
    this.active = true;

    this.common.serverdata('getStudentLevel', '').subscribe(
      res => {
        this.data = res;
        this.studentLevel = this.data.response;
        console.log(this.studentLevel);
        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }

  mylevel(level, id) {
    this.active = false;
    this.studentlevel = level;
    this.student_levels_id = id;
  }

  selectslot() {

    window.scrollTo(0, 0)

    this.active = true;
    this.levelselection = false;
    this.slotselection = true;
    this.myform = false;

    this.monday = '';
    this.tuesday = '';
    this.wednesday = '';
    this.thursday = '';
    this.friday = '';
    this.saturday = '';
    this.sunday = '';

    this.spinnerService.show();

    this.servicedata = {
      student_levels_id: this.student_levels_id
    }

    this.common.serverdatapost('getStudentSkillLevelField', this.servicedata).subscribe(
      res => {
        this.data = res;
        this.class_time = this.data.response.class_time;
        console.log(this.data.response.class_time);
        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )

  }

  selectagree() {
    this.active = false;
  }

  agree() {
    this.agreement = true;
    this.levelselection = false;
    this.slotselection = false;
  }

  selectlevel() {
    this.levelselection = true;
    this.agreement = false;
    this.slotselection = false;
    this.getStudentLevel();
  }

  addSlot(id, day, available) {
    
    console.log(day + " " + id + " " + available);
    if(!available){
      alert('class already full');
      return false;
    }
    
    
    

    this.active = false;

    if (day == 'monday') {
      if (this.monday == id) {
        this.monday = '';
        // this.active = false;
        return;
      }
      this.monday = id;
    }
    if (day == 'tuesday') {
      if (this.tuesday == id) {
        this.tuesday = '';
        return;
      }
      this.tuesday = id;
    }
    if (day == 'wednesday') {
      if (this.wednesday == id) {
        this.wednesday = '';
        return;
      }
      this.wednesday = id;
    }
    if (day == 'thursday') {
      if (this.thursday == id) {
        this.thursday = '';
        return;
      }
      this.thursday = id;
    }
    if (day == 'friday') {
      if (this.friday == id) {
        this.friday = '';
        return;
      }
      this.friday = id;
    }
    if (day == 'saturday') {
      if (this.saturday == id) {
        this.saturday = '';
        return;
      }
      this.saturday = id;
    }
    if (day == 'sunday') {
      if (this.sunday == id) {
        this.sunday = '';
        return;
      }
      this.sunday = id;
    }

    console.log(this.monday,this.tuesday,this.wednesday)


  }

  fillform() {
    window.scrollTo(0, 0)

    this.slotselection = false;
    this.myform = true;
  }

  enroll() {

    window.scrollTo(0, 0);

    this.spinnerService.show();
    // console.log(this.selectedClass);

    this.selectedClass = [];

    if (this.monday) this.selectedClass.push(this.monday);
    if (this.tuesday) this.selectedClass.push(this.tuesday);
    if (this.wednesday) this.selectedClass.push(this.wednesday);
    if (this.thursday) this.selectedClass.push(this.thursday);
    if (this.friday) this.selectedClass.push(this.friday);
    if (this.saturday) this.selectedClass.push(this.saturday);
    if (this.sunday) this.selectedClass.push(this.sunday);

    console.log(this.selectedClass);

    // this.servicedata = {
    //   StudentLevelId: this.student_levels_id,
    //   ClassTime: JSON.stringify(this.selectedClass),
    //   Email: this.emailId,
    //   FirstName: this.firstName,
    //   LastName: this.lastName,
    //   EmergencyContactName: this.emergencyContactName,
    //   EmergencyContactNumber: this.emergencyContactPhone,
    //   SchoolGrade: this.schoolGrade,
    //   FatherFirstName: this.fatherFirstName,
    //   FatherLastName: this.fatherLastName,
    //   FatherContactNumber: this.fatherContactNumber,
    //   MotherFirstName: this.motherFirstName,
    //   MotherLastName: this.motherLastName,
    //   Address: this.homeAddress,
    //   City: this.city,
    //   State: this.state,
    //   ZipCode: this.zipCode,
    //   Agree: 'yes',
    //   From: 'Website',
    //   Type: '2'
    // }

    const formData: FormData = new FormData();
      
    formData.append('StudentLevelId', this.student_levels_id);
    formData.append('ClassTime', JSON.stringify(this.selectedClass));
    formData.append('Email', this.emailId);
    formData.append('FirstName', this.firstName);
    formData.append('LastName', this.lastName);
    formData.append('EmergencyContactName', this.emergencyContactName);
    formData.append('EmergencyContactNumber', (this.emergencyContactPhone)?this.emergencyContactPhone:'');
    formData.append('SchoolGrade', this.schoolGrade);
    formData.append('FatherFirstName', this.fatherFirstName);
    formData.append('FatherLastName', this.fatherLastName);
    formData.append('FatherContactNumber', (this.fatherContactNumber)?this.fatherContactNumber:'');
    formData.append('MotherFirstName', this.motherFirstName);
    formData.append('MotherLastName', this.motherLastName);
    formData.append('Address', this.homeAddress);
    formData.append('City', this.city);
    formData.append('State', this.state);
    formData.append('ZipCode', this.zipCode);
    formData.append('Agree', 'yes');
    formData.append('From', 'Website');
    formData.append('Type', '2');
    formData.append('Profile', this.profile);
    formData.append('Signature', this.signatureImg);


    console.log(formData)

    this.common.serverdatapost('addContractService2', formData).subscribe(
      res => {
        this.data = res;
        if( this.data.status ){
          this.successMessage = this.data.message;
          setTimeout( () => {
            document.getElementById('successModal').style.display = "block";
          },0);
          setTimeout( () => {
            document.getElementById('successModal').style.display = "none";
            this.router.navigateByUrl("/");
          },5000);  
        }
        else {
          this.errors = this.data.result;
        }
        this.spinnerService.hide();
      },
      errors => {
        this.errorMessage = 'Something went wrong!';
        this.spinnerService.hide();
      }
    )
  }

  profileSrc:any = "";

  uploadProfile(str: any) {
    this.profile = str.target.files[0];
    console.log(this.profile)
    if ( this.profile ) {
      const reader = new FileReader();
      reader.onload = e => this.profileSrc = reader.result;
      reader.readAsDataURL(this.profile);
    }

  }


}
