import { Component, Input, OnInit } from '@angular/core';
// 

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  
  activeIndex = 0;
  intervalId: any;
  @Input() caption:boolean=false;

  constructor() { }

  ngOnInit(): void {

    
   
  }

  

 


}