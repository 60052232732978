import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  data:any;
  email:any;
  servicedata:any;
  first_name:any;
  last_name:any;
  mobile:any;
  m_id:any;

  member_no:any;
  old_password:any;
  new_password:any;
  cpassword:any;

  successMessage:any;
  errorMessage:any;
  
  constructor(private common:CommonService,private spinnerService: Ng4LoadingSpinnerService) {
    this.data = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    this.member_no = this.data;
   }

  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile(){
    this.spinnerService.show();
        this.servicedata = {
          member_id:this.data,
          email:this.email,
        }
      
      this.common.serverdatapost('getMemberProfile?member_number='+this.data,this.servicedata).subscribe(
        res => {
              this.data = res;
              console.log(this.data.response);
              let userProfile = this.data.response;
              this.first_name = userProfile.first_name;
              this.last_name = userProfile.last_name;
              this.email = userProfile.email;
              this.mobile = userProfile.mobile;
              this.m_id = userProfile.member_no;
              this.spinnerService.hide();
        },
        errors => {
          console.log(errors);
          this.spinnerService.hide();
        }
      )
  }


      updatePassword(){

        this.spinnerService.show();

        this.servicedata = {
           member_no: this.member_no,
            old_password: this.old_password,
            new_password: this.new_password,
            conf_password: this.cpassword
        }
      
      this.common.serverdatapost('changeMemberPassword',this.servicedata).subscribe(
        res => {
              this.data = res;
              alert(this.data.message);

              if(this.data.status == true){
                this.successMessage = this.data.message;
                setTimeout( () => {
                  document.getElementById('successModal').style.display = "block";
                },500);
                setTimeout( () => {
                  document.getElementById('successModal').style.display = "none";
                },3000);  
              }
              
              if(this.data.status == false){
  
                this.errorMessage = this.data.message;
                setTimeout( () => {
                  document.getElementById('errorModal').style.display = "block";
                },500);
                setTimeout( () => {
                  document.getElementById('errorModal').style.display = "none";
                },3000);
              }
  
              this.spinnerService.hide();
        },
        errors => {
          console.log(errors);
          this.spinnerService.hide();
        }
      )
    }

}
