import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
import * as moment from 'moment';


@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  data: any = [];
  studentLabels:any = [];
  schedule: any = '';
  scheduleclass: any = [];
  
  studentlevel:any=[];
  allcoaches:any=[];
  servicedata:any=[];
  name:any;
  email:any;
  mobile_no:any;
  residence:any;
  history:any;
  reference:any;
  certificate:any;
  summary:any;
  successMessage: any = '';
  errorMessage: any = '';
  schedules: any = [];


  constructor(private common: CommonService,private router :Router) {
    this.getWebsiteSetting();
  }

  ngOnInit() {
    this.getSchedule();
    this.getStudentLabel();
    this.getcoaches()

  }

  getWebsiteSetting() {

    this.common.serverdata('getWebsiteSetting?page=training&section=schedule', []).subscribe(
      res => {
        this.data = res;
        console.log(this.data)
        this.schedule = this.data.response[0].dataset[0].value
      },
      errors => {
        console.log(errors);
      }
    )
  }

  getSchedule() {
    this.common.serverdata('getClassTiming', []).subscribe(
      res => {
        this.scheduleclass = res;
        console.log(this.scheduleclass);
        this.schedules = this.splitArrayIntoChunksOfLen(this.scheduleclass.response, 2);
        console.log(this.schedules);

      },
      err => {
        console.log(err)
      }
    );
  }

  convertTime(time) {
    let a = moment(time, ["HH:mm"]).format('h:mm a');
    return a;
  }
  
    splitArrayIntoChunksOfLen(arr, len) {
      var chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    }
  

  getStudentLabel(){
    this.common.serverdata('getStudentLevel', []).subscribe(
      res => {
        this.data = res;
        this.studentLabels = this.data.response;
        console.log(this.studentLabels);
      },
      err => {
        console.log(err)
      }
    );
  }

  getcoaches(){
    this.common.serverdata('getCoaches',[]).subscribe(
      res=>{
        this.data=res;
        this.allcoaches=this.data.result;
        console.log(this.allcoaches);
        
      },
      err=>{
        console.log(err)
      }
    )
  }

  coachenquiry(){
    this.servicedata={
      name:this.name,
      email:this.email,
      residence:this.residence,
      summary:this.summary,
      certificate:this.certificate,
      history:this.history,
      reference:this.reference,
      mobile:this.mobile_no
    }
    this.common.serverdatapost('addCoachInquiry',this.servicedata).subscribe(
      res=>{
        this.data=res;
        console.log(this.data);
        if(this.data.status){
          this.successMessage = this.data.message;
            setTimeout(() => {
              document.getElementById('successModal').style.display = "block";
            }, 500);
            setTimeout(() => {
              document.getElementById('successModal').style.display = "none";
              location.reload()
            }, 3000);

        }
      },
      err=>{
        console.log(err)
      }
    );
  }

}
