import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-achievement',
  templateUrl: './achievement.component.html',
  styleUrls: ['./achievement.component.css']
})
export class AchievementComponent implements OnInit {

  data: any;
  allachievement:any=[];
  mid: any;
  bid: any;
  servicedata: any = [];
  member_id: any;
  is_like: any = "false";


  constructor(private common: CommonService, private route: Router) { }
  ngOnInit() {
    this.getAchievements();
    this.member_id = localStorage.getItem('memberId');
    console.log(this.member_id)
  }
  getAchievements(){
    this.common.serverdata('getAchievements',[]).subscribe(
      res=>{
        this.data=res;
        this.allachievement=this.data.result;
        console.log(this.allachievement);
        
      },
      err=>{
        console.log(err)
      }
    )
  }
  
  like(bid) {
    console.log(bid);
    if (localStorage.getItem("isLoggedIn") != "yes") {
      this.route.navigate(['/login']);
    }
    else {
      this.servicedata = {
        "blog_id": bid,
        "member_id": localStorage.getItem("memberId")
      }
      this.common.serverdatapost('likeBlog', this.servicedata).subscribe(
        (res) => {
          this.data = res;
          console.log(this.data);
          if (this.data.status) {
            this.getAchievements();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  unlike(bid) {
    console.log(bid);
    if (localStorage.getItem("isLoggedIn") != "yes") {
      this.route.navigate(['/login']);

    }
    else {

      this.servicedata = {
        "blog_id": bid,
        "member_id": localStorage.getItem("memberId")

      }
      this.common.serverdatapost('unlikeBlog', this.servicedata).subscribe(
        (res) => {
          this.data = res;
          console.log(this.data);
          if (this.data.status) {
            this.getAchievements();
          }

        },
        (err) => {
          console.log(err);
        }
      );
    }

  }
 

}
