import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  data: any = [];
  servicedata:any=[];
  fname:any;
  lname:any
  email:any;
  mobile:any;
  message:any;
  successMessage: any = '';
  errorMessage: any = '';
 
  constructor(private common: CommonService,private router :Router) { }

  ngOnInit() {
    
  }
  inquiry(){
    this.servicedata={
      fname:this.fname,
      lname:this.lname,
      email:this.email,
      mobile:this.mobile,
      message:this.message,

    }
    this.common.serverdatapost('addContactus',this.servicedata).subscribe(
      res=>{
        this.data=res;
        console.log(this.data);
        if(this.data.status){
          this.successMessage = this.data.message;
            setTimeout(() => {
              document.getElementById('successModal').style.display = "block";
            }, 500);
            setTimeout(() => {
              document.getElementById('successModal').style.display = "none";
              location.reload()
            }, 3000);
        }
      },
      err=>{
        console.log(err)
      }
    );
  }
}
