import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {
  data: any;
  blogs: any = [];
  tags = [];
  mid: any;
  bid: any;
  servicedata: any = [];
  member_id: any;
  is_like: any = "false";

  constructor(private common: CommonService, private route: Router) { }

  ngOnInit() {
    this.getblogs();
    this.member_id = localStorage.getItem('memberId');
    console.log(this.member_id)
  }

  getblogs() {
    let url = "getPublishedBlog";
    if (localStorage.getItem("isLoggedIn") == "yes")
      url = "getPublishedBlog?member_id=" + localStorage.getItem("memberId")
    this.common.serverdatafrisco(url, []).subscribe(
      res => {
        this.data = res;
        console.log(this.data);
        if (this.data.status == true) {
          this.blogs = this.data.result.reverse();
          console.log(this.blogs);
        }
      },
      err => {
        console.log(err);
      }
    );

  }
  navigate(id) {
    console.log(id);
    this.route.navigate(['/blogdetail', id]);
  }

  like(bid) {
    console.log(bid);
    if (localStorage.getItem("isLoggedIn") != "yes") {
      this.route.navigate(['/login']);
    }
    else {
      this.servicedata = {
        "blog_id": bid,
        "member_id": localStorage.getItem("memberId")
      }
      this.common.serverdatapost('likeBlog', this.servicedata).subscribe(
        (res) => {
          this.data = res;
          console.log(this.data);
          if (this.data.status) {
            this.getblogs();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  unlike(bid) {
    console.log(bid);
    if (localStorage.getItem("isLoggedIn") != "yes") {
      this.route.navigate(['/login']);

    }
    else {

      this.servicedata = {
        "blog_id": bid,
        "member_id": localStorage.getItem("memberId")

      }
      this.common.serverdatapost('unlikeBlog', this.servicedata).subscribe(
        (res) => {
          this.data = res;
          console.log(this.data);
          if (this.data.status) {
            this.getblogs();
          }

        },
        (err) => {
          console.log(err);
        }
      );
    }

  }
}