import { Component, OnInit } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  data: any = [];
  alltrainingvideos=[];


  constructor(private common: CommonService,private router :Router,private sanitizer: DomSanitizer) { }
// 
  ngOnInit() {
    this.getTrainingvideos()

  }
  getTrainingvideos(){
    this.common.serverdata('getTrainingvideos',[]).subscribe(
      res=>{
        this.data=res;
        this.alltrainingvideos=this.data.result;
        //  this.sanitizer.bypassSecurityTrustUrl(this.alltrainingvideos[1].url);
        console.log(this.alltrainingvideos);
        
      },
      err=>{
        console.log(err)
      }
    )
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
