import { Component, OnInit } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {

  datas:any
  plans:any
  constructor(private common: CommonService,private spinnerService: Ng4LoadingSpinnerService) { }

  ngOnInit() {
    this.getMemberPlan()
  }

  getMemberPlan() {

    this.spinnerService.show();

    this.common.serverdata('getWebsiteSetting?page=membership', '').subscribe(
      res => {
        this.datas = res;
        console.log(this.datas)
        this.datas.response.forEach(element => {
            console.log(element);
        });
        this.spinnerService.hide();
      },
      errors => {
        console.log(errors);
        this.spinnerService.hide();
      }
    )
  }

}
