import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  data:any;
  email:any;
  servicedata:any;
  first_name:any;
  last_name:any;
  mobile:any;
  m_id:any;
  profileimage:any = 'assets/default-profile.png';

  socmenu:any = 2;

  constructor(private common:CommonService, private router: Router) {
    this.data = localStorage.getItem('currentUserData');
    this.email = localStorage.getItem('emailID');
    if(!this.data) this.router.navigateByUrl('/login');
   }

  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile(){
        this.servicedata = {
          member_id:this.data,
          email:this.email,
        }
      
      this.common.serverdatapost('getMemberProfile?member_number='+this.data,this.servicedata).subscribe(
        res => {
              this.data = res;
              console.log(this.data.response);
              let userProfile = this.data.response;
              this.first_name = userProfile.first_name;
              this.last_name = userProfile.last_name;
              this.email = userProfile.email;
              this.mobile = userProfile.mobile;
              this.m_id = userProfile.id;
              this.profileimage = userProfile.profile;
        },
        errors => {
          console.log(errors);
        }
      )
  }

  logout()
  {
    localStorage.clear();
    this.router.navigate(['login'])
    .then(() => {
      window.location.reload();
    });
  }

  ocmenu(oc)
  {
    if(oc == 1) this.socmenu = 2;
    if(oc == 2) this.socmenu = 1;
  }


}
